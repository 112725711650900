.contentWrapper {
  padding-bottom: 100px;
  .headerDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 0px 20px;
    margin:30px 0px 50px 0px;
    border:1px solid #e4e5e7;

    p {
      color:#6a6c6f;
      margin: 15px 0px;
      font-size: 18px;
      font-weight: 500;
    }

    .headerBtns {
      display: flex;
      gap: 10px;

      a, button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        text-decoration: none;
        border-radius: 5px;
        padding: 4px 8px;
        font-size: 12px;
        font-weight: 500;
        border:none;
      }
      a {
        color: #fff;
        background-color: #3498db;
      }
      button {
        color: #fff;
        background-color: #198b54;
        &:disabled {
          opacity: 0.5;
          cursor: inherit;
        }
      }
    }
  }
}
