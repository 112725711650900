.content {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .box {
    height: 90px;
    background-color: #fff;
    border-radius: 8px;
  }
}
